<template>
  <div class="newSiteWarpNS">
    <div class="newsite-flex"
         ref="scrollEl">
      <div class="newSiteWarpNS-top">
        <step :stepArr="stepArr" :stepFull="false"
              class="newSiteWarpNS-step"></step>
        <van-tabs v-model="selectedTab"
                  @click="changeTab(selectedTab)"
                  color="#3F7C53"
                  title-active-color="#3F7C53"
                  line-width="70px"
                  style="background-color: #FFFFFF;">
          <van-tab v-for="(item,index) in tabList"
                   :key="index"
                   :title="item.name"></van-tab>
        </van-tabs>
      </div>
      <!-- tab-header -->
      <div class="detail-content"
           ref="content">
        <!-- tab content -->
        <div class="tab-content">
          <div class="tab-content__item">
            <div class="step-one">
              <div class="title">基本信息</div>
              <van-field clickable
                         readonly
                         v-if="!isFirstShowLong"
                         v-model="addressVal"
                         label="省市区县"
                         placeholder="省份、城市、区县"
                         @click="addressShow = true"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         autosize
                         v-if="isFirstShowLong"
                         input-align="right"
                         type="textarea"
                         v-model="addressVal"
                         label="省市区县"
                         placeholder="省份、城市、区县"
                         @click="addressShow = true"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         v-if="!isSecondShowLong"
                         v-model="pageInfoShowVal.countryVal"
                         label="乡镇村"
                         placeholder="乡镇村"
                         @click="countryClick"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         v-if="isSecondShowLong"
                         autosize
                         input-align="right"
                         type="textarea"
                         v-model="pageInfoShowVal.countryVal"
                         label="乡镇村"
                         placeholder="乡镇村"
                         @click="countryClick"
                         right-icon="arrow-down"></van-field>
              <van-field readonly
                         v-model="pageInfo.stationName"
                         label="站点名称"
                         placeholder="村名+服务站"></van-field>
              <van-field v-model="pageInfoShowVal.gps"
                         clickable
                         readonly
                         @click="gpsClick"
                         label="站点GPS位置"
                         right-icon="location-o"></van-field>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.recommendSource"
                         label="站点推荐来源"
                         placeholder="请选择"
                         @click="clickSiteSource"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.placeNature"
                         label="站点场所性质"
                         placeholder="请选择"
                         @click="clickSiteProp"
                         right-icon="arrow-down"></van-field>
              <van-field v-model="pageInfoShowVal.otherInfo"
                         style="margin-top:0.2rem;"
                         type="textarea"
                         placeholder="乡村信息有系统自动带出，不需要编辑"
                         :autosize=true
                         readonly></van-field>
            </div>
          </div>
          <div class="tab-content__item">
            <div class="step-two">
              <div class="title">人口信息</div>
              <van-field v-model="pageInfo.households"
                         label="户数"
                         placeholder="请输入本村户数"
                         type="digit"
                         maxlength="5"
                         :formatter="NumFormatter"></van-field>
              <van-field v-model="pageInfo.peopleNum"
                         label="人数"
                         placeholder="请输入本村人数"
                         type="digit"
                         maxlength="5"
                         :formatter="NumFormatter"></van-field>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.aboRatio"
                         label="原著居民占比"
                         placeholder="请选择"
                         @click="selectClick('aboRatio','原著居民占比')"
                         right-icon="arrow-down"></van-field>

              <div class="title">经济信息</div>
              <van-field v-model="pageInfo.perLand"
                         label="人均耕地(亩)"
                         placeholder="请输入人均耕地亩数"
                         maxlength="4"
                         type="number"
                         :formatter="LandFormatter"></van-field>
              <van-field clickable
                         readonly
                         label-width="4rem"
                         v-model="pageInfoShowVal.houseDisposableFound"
                         label="户均可支配资金"
                         placeholder="请选择"
                         @click="selectClick('houseDisposableFound','户均可支配资金')"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         label-width="4rem"
                         v-model="pageInfoShowVal.carRatio"
                         label="私家车(占户数比例)"
                         placeholder="请选择"
                         @click="selectClick('carRatio','私家车(占户数比例)')"
                         right-icon="arrow-down"></van-field>

              <div class="title">居住信息</div>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.livingDensity"
                         label="居住密度"
                         placeholder="请选择"
                         @click="selectClick('livingDensity','居住密度')"
                         right-icon="arrow-down"></van-field>
              <div class="title">文化信息</div>
              <van-cell title="特色地方文化、历史名人等">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.distinctiveCulture">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field v-if="pageInfo.distinctiveCulture == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.regionalImpact"
                         label="地域影响"
                         placeholder="请选择"
                         @click="selectClick('regionalImpact','地域影响')"
                         right-icon="arrow-down"></van-field>

              <div class="mt2"></div>
              <van-cell title="美好示范乡村、明星村落等">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.demonstrationVillage">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field v-if="pageInfo.demonstrationVillage == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.demonstrationLevel"
                         label="示范等级"
                         placeholder="请选择"
                         @click="selectClick('demonstrationLevel', '示范等级')"
                         right-icon="arrow-down"></van-field>

              <div class="title">综合信息</div>
              <van-cell title="村集体经济">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.villageCollectiveEconomy">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="近年拆迁信息">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.recentDemolitionInfo">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="集贸市场">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.pedlarsMarket">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="快递收发点">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.expressPoint">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="水电气等代缴点">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.paymentPoint">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="同类站点">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.generalInfo.similarPoint">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>

              <div class="title">周边金融网点</div>
              <van-checkbox-group v-model="pageInfo.nearFinancePoint">
                <van-checkbox :name="item.dictKey"
                              class="checkboxitem"
                              checked-color="#3F7C53"
                              v-for="(item, index) in selectList.nearFinancePoint"
                              :key="index">{{item.dictValue}}</van-checkbox>
              </van-checkbox-group>

              <div class="mt2"></div>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.nearFinancePointDistance"
                         label-width="4rem"
                         label="最近金融网点距离(KM)"
                         placeholder="请选择"
                         @click="selectClick('nearFinancePointDistance', '最近金融网点距离(KM)')"
                         right-icon="arrow-down"></van-field>

            </div>
          </div>
          <div class="tab-content__item">
            <div class="step-three">
              <div class="title">补充信息</div>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.mainIncomeSource"
                         label="主要经济来源"
                         placeholder="请选择"
                         @click="selectClick('mainIncomeSource', '主要经济来源')"
                         right-icon="arrow-down"></van-field>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.plantIndustry"
                         label="种植业类型"
                         placeholder="请选择"
                         @click="selectClick('plantIndustry', '种植业类型')"
                         right-icon="arrow-down"></van-field>

              <div class="mt2"></div>
              <van-cell title="是否有养殖业">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.hasBreedIndustry">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field v-if="pageInfo.hasBreedIndustry == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.breedIndustryType"
                         label="养殖类类型"
                         placeholder="请选择"
                         @click="selectClick('breedIndustryType', '养殖类类型')"
                         right-icon="arrow-down"></van-field>

              <div class="mt2"></div>
              <van-cell title="是否有制造业">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.hasManufacture">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field v-if="pageInfo.hasManufacture == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.manufactureType"
                         label="制造业类型"
                         placeholder="请选择"
                         @click="selectClick('manufactureType', '制造业类型')"
                         right-icon="arrow-down"></van-field>
              <van-field v-if="pageInfo.hasManufacture == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.manufactureForm"
                         label="主要形式"
                         placeholder="请选择"
                         @click="selectClick('manufactureForm', '主要形式')"
                         right-icon="arrow-down"></van-field>
              <van-cell v-if="pageInfo.hasManufacture == '1'"
                        title="有无知名企业">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.hasFamousCompany">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>

              <div class="mt2"></div>
              <van-cell title="是否有服务业">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.hasServiceIndustry">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field v-if="pageInfo.hasServiceIndustry == '1'"
                         clickable
                         readonly
                         v-model="pageInfoShowVal.serviceIndustryType"
                         label="服务业类型"
                         placeholder="请选择"
                         @click="selectClick('serviceIndustryType', '服务业类型')"
                         right-icon="arrow-down"></van-field>
              <van-cell v-if="pageInfo.hasServiceIndustry == '1'"
                        title="周边旅游景点">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.hasNearScenicSpots">
                  <van-radio name="1">有
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">无 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>

              <div class="title">参考项目</div>
              <van-cell title="网络信号">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.referItems.netSignal">
                  <van-radio name="1">好
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">差 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-cell title="是否为乡镇驻地村">
                <van-radio-group checked-color="#3F7C53"
                                 class="radiogroup"
                                 direction="horizontal"
                                 v-model="pageInfo.referItems.townResident">
                  <van-radio name="1">是
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                  <van-radio name="0">否 
                    <template #icon="props">
                      <img class="img-icon" :src="props.checked ? checked : unchecked" />
                    </template>
                  </van-radio>
                </van-radio-group>
              </van-cell>
              <van-field clickable
                         readonly
                         v-model="pageInfoShowVal.bankSuggest"
                         label="行方建议"
                         placeholder="请选择"
                         @click="selectClick('bankSuggest', '行方建议')"
                         right-icon="arrow-down"></van-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="addressShow"
               position="bottom">
      <van-area :area-list="areaList"
                @cancel="addressShow = false"
                @confirm="areaConfirm"></van-area>
    </van-popup>

    <van-popup v-model="countryShow"
               position="bottom">
      <van-picker show-toolbar
                  title="乡镇村"
                  :columns="countryList"
                  @cancel="countryShow = false"
                  @confirm="countryConfirm"
                  visible-item-count='5' />
    </van-popup>

    <van-popup v-model="sitePropShow"
               position="bottom">
      <van-picker show-toolbar
                  title="站点场所性质"
                  :columns="sitePropList"
                  @cancel="sitePropShow = false"
                  @confirm="sitePropComfirm" />
    </van-popup>

    <van-popup v-model="siteSourceShow"
               position="bottom">
      <van-picker show-toolbar
                  title="站点推荐来源"
                  :columns="siteSourceList"
                  @cancel="siteSourceShow = false"
                  @confirm="siteSourceComfirm" />
    </van-popup>

    <van-popup v-model="popupShow"
               v-if="popupShow"
               position="bottom">
      <van-picker show-toolbar
                  :title="popuptitle"
                  :columns="popupList"
                  @cancel="popupShow = false"
                  @confirm="popupConfirm" />
    </van-popup>

    <div class="nextBtn">
      <div class="btn-active"
           @click="nextBtn">下一步</div>
    </div>
    <van-popup v-model="mapshow"
               v-if=" mapshow"
               position="bottom">
      <div class="mapDiv">
        <div class="close">
          <i @click="closeMapClick">
            <van-icon name="cross" />
          </i>
        </div>
        <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin" :events="events" class="amap-demo">
        </el-amap>
      </div>

    </van-popup>
  </div>
</template>

<script>
  import { AMapManager,lazyAMapApiLoaderInstance} from 'vue-amap';
  let amapManager = new AMapManager()
import areaList from "@/utils/area"
import common from '@/utils/common'
import Step from '@/components/Step'
import { saveStationInfo, getAreaInfoCountyDict, getDictByPage, getVillageInfoByAreaCode, getStationInfoByCode, getAreaInfoProvDict } from '@/utils/api'
  import {uwStatisticAction} from "../../utils/util";
export default {
  name: 'NewSite',
  components: {
    Step
  },
  data () {
    let self = this
    return {
      isFirstShowLong: false,
      isSecondShowLong: false,
      stepArr: [
        { name: "村情信息", isActive: true },
        { name: "站长信息", isActive: false }
      ],
      tabList: [
        { name: '基本信息' },
        { name: '重点调查' },
        { name: '补充调查' }
      ],
      selectedTab: 0,
      lineStyle: {},
      scrolled: false,
      // pageOne
      areaList,
      addressShow: false,
      countryShow: false,
      countryList: [],
      countryListAll: [],
      sitePropShow: false,
      sitePropList: [/* '自由','租赁','其它' */],
      sitePropApiList: [],
      isCanClick: false,
      addressVal: null,
      local: null,
      areaCode: null,
      areaArr: { province_list: {}, city_list: {}, county_list: {} },
      addressShowFlag: false,

      siteSourceShow: false,
      siteSourceList: [],
      siteSourceApiList: [],
      pageOneInfo: {},
      // pageTwo
      popupVal: '',
      popupShow: false,
      popupList: [],
      popuptitle: '',
      pageInfo: {
        locateValue: null,
        locateKey: null,
        villageCode: null,
        stationName: null,
        lng: null,
        lat: null,
        recommendSource: null,
        placeNature: null,
        households: null,
        peopleNum: null,
        aboRatio: null,
        perLand: null,
        houseDisposableFound: null,
        carRatio: null,
        livingDensity: null,
        distinctiveCulture: null,
        regionalImpact: null,
        demonstrationVillage: null,
        demonstrationLevel: null,
        generalInfo: {
          villageCollectiveEconomy: null,
          recentDemolitionInfo: null,
          pedlarsMarket: null,
          expressPoint: null,
          paymentPoint: null,
          similarPoint: null
        },
        nearFinancePoint: [],
        nearFinancePointDistance: null,
        mainIncomeSource: null,
        plantIndustry: null,
        hasBreedIndustry: null,
        breedIndustryType: null,
        hasManufacture: null,
        manufactureType: null,
        manufactureForm: null,
        hasFamousCompany: null,
        hasServiceIndustry: null,
        serviceIndustryType: null,
        hasNearScenicSpots: null,
        referItems: {
          netSignal: null,
          townResident: null
        },
        bankSuggest: null,
      },
      selectList: [],
      tempObj: null,
      pageInfoShowVal: {
        countryVal:null,
        gps: null,
        otherInfo: null,
        recommendSource: null,
        placeNature: null,
        aboRatio: null,
        houseDisposableFound: null,
        carRatio: null,
        livingDensity: null,
        regionalImpact: null,
        demonstrationLevel: null,
        nearFinancePointDistance: null,
        mainIncomeSource: null,
        plantIndustry: null,
        breedIndustryType: null,
        manufactureType: null,
        manufactureForm: null,
        serviceIndustryType: null,
        bankSuggest: null,
      },
      popupListArr: [],
      isSiteExist: false,
      nsCache:{},
      checked: require('@/assets/img/site/checked.png'),
      unchecked: require('@/assets/img/site/unchecked.png'),
      mapshow:false,
      amapManager,
      zoom: 12,
      center: [],
      events: {
        init: (o) => {
          console.log('顶顶顶顶')
          console.log(o)
          console.log(o.getCenter())
          console.log('这里：',this.$refs.map.$$getInstance())
          o.getCity(result => {
            console.log(result)
          })
        },
        'moveend': () => {
        },
        'zoomchange': () => {
        },
        'click': (e) => {
          console.log('map clicked',e);
          let { lng, lat } = e.lnglat
          self.pageInfo.lat = lat
          self.pageInfo.lng = lng
          console.log("self.pageInfo",self.pageInfo)
          self.center = [lng, lat]

          let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress([lng, lat], function (status, result) {
            console.log("地址：",result)
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                console.log(result.regeocode.formattedAddress)
                self.pageInfoShowVal.gps = result.regeocode.formattedAddress

                self.mapshow = false
                self.$nextTick()
              }
            }
          })
        }
      },
      plugin: ['ToolBar', {
        pName: 'MapType',
        defaultType: 0,
        events: {
          init(o) {
            console.log(o);

          }
        }
      }]

    };
  },
  watch: {
    'pageInfo.distinctiveCulture' (newval) {
      if (newval == '0') {
        this.popupCancel('regionalImpact');
      }
    },
    'pageInfo.demonstrationVillage' (newval) {
      if (newval == '0') {
        this.popupCancel('demonstrationLevel');
      }
    },
    'pageInfo.hasBreedIndustry' (newval) {
      if (newval == '0') {
        this.popupCancel('breedIndustryType');
      }
    },
    'pageInfo.hasManufacture' (newval) {
      if (newval == '0') {
        this.popupCancel('manufactureType');
        this.popupCancel('manufactureForm');
        this.pageInfo.hasFamousCompany = null;
      }
    },
    'pageInfo.hasServiceIndustry' (newval) {
      if (newval == '0') {
        this.popupCancel('serviceIndustryType');
        this.pageInfo.hasNearScenicSpots = null;
      }
    },
    pageInfo:{
      handler(val){
        if(!common.getItem(common.getItem('uniqueNo'))) return
        let oldValue = common.getItem(common.getItem('uniqueNo')).sitecq ?common.getItem(common.getItem('uniqueNo')).sitecq:{}
        if(val){
          console.log("监听：",val)
          oldValue.locateValue = val.locateValue
          oldValue.locateKey = val.locateKey
          oldValue.villageCode = val.villageCode
          oldValue.stationName = val.stationName
          oldValue.lng = val.lng
          oldValue.lat = val.lat
          self.center = [val.lng,val.lat]
          oldValue.recommendSource = val.recommendSource
          oldValue.placeNature = val.placeNature
          // 人口信息
          oldValue.households = val.households
          oldValue.peopleNum = val.peopleNum
          oldValue.aboRatio = val.aboRatio
          oldValue.perLand = val.perLand
          oldValue.houseDisposableFound = val.houseDisposableFound
          oldValue.carRatio = val.carRatio
          oldValue.livingDensity = val.livingDensity
          oldValue.distinctiveCulture = val.distinctiveCulture
          oldValue.regionalImpact = val.regionalImpact
          oldValue.demonstrationVillage = val.demonstrationVillage
          oldValue.demonstrationLevel = val.demonstrationLevel
          // 综合经济
          oldValue.generalInfo = {}
          oldValue.generalInfo.villageCollectiveEconomy = val.generalInfo.villageCollectiveEconomy
          oldValue.generalInfo.recentDemolitionInfo = val.generalInfo.recentDemolitionInfo
          oldValue.generalInfo.pedlarsMarket = val.generalInfo.pedlarsMarket
          oldValue.generalInfo.expressPoint = val.generalInfo.expressPoint
          oldValue.generalInfo.paymentPoint = val.generalInfo.paymentPoint
          oldValue.generalInfo.similarPoint = val.generalInfo.similarPoint
          //周边金融网点
          // oldValue.nearFinancePoint = JSON.stringify(val.nearFinancePoint)
          oldValue.nearFinancePoint = val.nearFinancePoint
          oldValue.nearFinancePointDistance = val.nearFinancePointDistance
          //补充证明
          oldValue.mainIncomeSource = val.mainIncomeSource
          oldValue.plantIndustry = val.plantIndustry

          oldValue.hasBreedIndustry = val.hasBreedIndustry
          oldValue.breedIndustryType = val.breedIndustryType
          oldValue.hasManufacture = val.hasManufacture
          oldValue.manufactureType = val.manufactureType
          oldValue.manufactureForm = val.manufactureForm
          oldValue.hasFamousCompany = val.hasFamousCompany
          oldValue.hasServiceIndustry = val.hasServiceIndustry
          oldValue.serviceIndustryType = val.serviceIndustryType
          oldValue.hasNearScenicSpots = val.hasNearScenicSpots
          // 参考项目
          oldValue.referItems = {}
          oldValue.referItems.netSignal = val.referItems.netSignal
          oldValue.referItems.townResident = val.referItems.townResident
          oldValue.bankSuggest = val.bankSuggest

          let cacheObj = common.getItem(common.getItem('uniqueNo'))
          cacheObj.sitecq = oldValue
          common.setItem(common.getItem('uniqueNo'), cacheObj)
        }
      },
      deep: true
    },
    pageInfoShowVal:{
      handler(val){
        if(!common.getItem(common.getItem('uniqueNo'))) return
        let oldValue = common.getItem(common.getItem('uniqueNo')).sitecqShow ?common.getItem(common.getItem('uniqueNo')).sitecqShow:{}
        if(val){
          oldValue.countryVal = val.countryVal
          oldValue.gps = val.gps
          /*this.pageInfo.lng = this.$store.state.mapshow.lng
          this.pageInfo.lat = this.$store.state.mapshow.lat*/
          oldValue.otherInfo = val.otherInfo
          oldValue.recommendSource = val.recommendSource
          oldValue.placeNature = val.placeNature
          oldValue.aboRatio = val.aboRatio
          oldValue.perLand = val.perLand
          oldValue.houseDisposableFound = val.houseDisposableFound
          oldValue.carRatio = val.carRatio
          oldValue.livingDensity = val.livingDensity
          oldValue.regionalImpact = val.regionalImpact
          oldValue.demonstrationLevel = val.demonstrationLevel
          oldValue.nearFinancePointDistance = val.nearFinancePointDistance
          oldValue.mainIncomeSource = val.mainIncomeSource
          oldValue.plantIndustry = val.plantIndustry
          oldValue.breedIndustryType = val.breedIndustryType
          oldValue.manufactureType = val.manufactureType
          oldValue.manufactureForm = val.manufactureForm
          oldValue.serviceIndustryType = val.serviceIndustryType
          oldValue.bankSuggest = val.bankSuggest

          let cacheObj = common.getItem(common.getItem('uniqueNo'))
          cacheObj.sitecqShow =  oldValue
          common.setItem(common.getItem('uniqueNo'), cacheObj)
        }
      },
      deep: true
    }
  },
  destroyed () {
    if (this.$refs.scrollEl) {
      this.$refs.scrollEl.removeEventListener("scroll", this.handleRoll);
    }
    // this.$store.dispatch('mapshow/mapClick', false)
  },
  mounted () {
    this.$store.dispatch('keepAliveState/addKeepAliveAction', 'NewSite')
    this.$refs.scrollEl.addEventListener('scroll', this.handleRoll)
    this.setLine()
    this.getPageInfo() // 获取下拉框信息
    if(common.getItem(common.getItem('uniqueNo'))){
      this.getCache()
    }else{
      let key = common.getItem('uniqueNo')
      let value = {
        sitecq: {},
        sitecqShow: {},
        sitezz: {},
        sitezzShow: {}
      }
      common.setItem(key, value)
    }
    uwStatisticAction('/NewSite','新建站点')
  },
  methods: {
    closeMapClick(){
      this.mapshow = false
    },
    getCache() {
      let oldValue = common.getItem(common.getItem('uniqueNo')).sitecq ?common.getItem(common.getItem('uniqueNo')).sitecq:{}
      if(oldValue){
        this.pageInfo.locateValue = oldValue.locateValue
        this.pageInfo.locateKey = oldValue.locateKey
        if(this.pageInfo.locateValue){
          this.addressVal = this.pageInfo.locateValue.province + ' ' + this.pageInfo.locateValue.city + ' ' + this.pageInfo.locateValue.county
          this.getAreaInfoCounty(this.pageInfo.locateKey.county)
        }
        this.pageInfo.villageCode = oldValue.villageCode
        this.pageInfo.stationName = oldValue.stationName
        this.pageInfo.lng = oldValue.lng
        this.pageInfo.lat = oldValue.lat
        this.center = [this.pageInfo.lng,this.pageInfo.lat]
        this.pageInfo.recommendSource = oldValue.recommendSource
        this.pageInfo.placeNature = oldValue.placeNature
        // 人口信息
        this.pageInfo.households = oldValue.households
        this.pageInfo.peopleNum = oldValue.peopleNum
        this.pageInfo.aboRatio = oldValue.aboRatio
        this.pageInfo.perLand = oldValue.perLand
        this.pageInfo.houseDisposableFound = oldValue.houseDisposableFound
        this.pageInfo.carRatio = oldValue.carRatio
        this.pageInfo.livingDensity = oldValue.livingDensity
        this.pageInfo.distinctiveCulture = oldValue.distinctiveCulture
        this.pageInfo.regionalImpact = oldValue.regionalImpact
        this.pageInfo.demonstrationVillage = oldValue.demonstrationVillage
        this.pageInfo.demonstrationLevel = oldValue.demonstrationLevel

        this.pageInfo.generalInfo.villageCollectiveEconomy = oldValue.generalInfo.villageCollectiveEconomy
        this.pageInfo.generalInfo.recentDemolitionInfo = oldValue.generalInfo.recentDemolitionInfo
        this.pageInfo.generalInfo.pedlarsMarket = oldValue.generalInfo.pedlarsMarket
        this.pageInfo.generalInfo.expressPoint = oldValue.generalInfo.expressPoint
        this.pageInfo.generalInfo.paymentPoint = oldValue.generalInfo.paymentPoint
        this.pageInfo.generalInfo.similarPoint = oldValue.generalInfo.similarPoint
        this.pageInfo.nearFinancePoint = oldValue.nearFinancePoint
        this.pageInfo.nearFinancePointDistance = oldValue.nearFinancePointDistance

        this.pageInfo.mainIncomeSource = oldValue.mainIncomeSource
        this.pageInfo.plantIndustry = oldValue.plantIndustry
        this.pageInfo.hasBreedIndustry = oldValue.hasBreedIndustry
        this.pageInfo.breedIndustryType = oldValue.breedIndustryType
        this.pageInfo.hasManufacture = oldValue.hasManufacture
        this.pageInfo.manufactureType = oldValue.manufactureType
        this.pageInfo.manufactureForm = oldValue.manufactureForm
        this.pageInfo.hasFamousCompany = oldValue.hasFamousCompany
        this.pageInfo.hasServiceIndustry = oldValue.hasServiceIndustry
        this.pageInfo.serviceIndustryType = oldValue.serviceIndustryType
        this.pageInfo.hasNearScenicSpots = oldValue.hasNearScenicSpots
        this.pageInfo.referItems = oldValue.referItems
        this.pageInfo.referItems.netSignal = oldValue.referItems.netSignal
        this.pageInfo.referItems.townResident = oldValue.referItems.townResident
        this.pageInfo.bankSuggest = oldValue.bankSuggest

      }
      let oldValueShowVal = common.getItem(common.getItem('uniqueNo')).sitecqShow ?common.getItem(common.getItem('uniqueNo')).sitecqShow:{}
      if(oldValueShowVal){
        this.pageInfoShowVal.countryVal = oldValueShowVal.countryVal
        this.pageInfoShowVal.gps = oldValueShowVal.gps
        this.pageInfoShowVal.otherInfo = oldValueShowVal.otherInfo
        this.pageInfoShowVal.recommendSource = oldValueShowVal.recommendSource
        this.pageInfoShowVal.placeNature = oldValueShowVal.placeNature
        this.pageInfoShowVal.aboRatio = oldValueShowVal.aboRatio
        this.pageInfoShowVal.houseDisposableFound = oldValueShowVal.houseDisposableFound
        this.pageInfoShowVal.carRatio = oldValueShowVal.carRatio
        this.pageInfoShowVal.livingDensity = oldValueShowVal.livingDensity
        this.pageInfoShowVal.regionalImpact = oldValueShowVal.regionalImpact
        this.pageInfoShowVal.demonstrationLevel = oldValueShowVal.demonstrationLevel
        this.pageInfoShowVal.nearFinancePointDistance = oldValueShowVal.nearFinancePointDistance
        this.pageInfoShowVal.mainIncomeSource = oldValueShowVal.mainIncomeSource
        this.pageInfoShowVal.plantIndustry = oldValueShowVal.plantIndustry
        this.pageInfoShowVal.breedIndustryType = oldValueShowVal.breedIndustryType
        this.pageInfoShowVal.manufactureType = oldValueShowVal.manufactureType
        this.pageInfoShowVal.manufactureForm = oldValueShowVal.manufactureForm
        this.pageInfoShowVal.serviceIndustryType = oldValueShowVal.serviceIndustryType
        this.pageInfoShowVal.bankSuggest = oldValueShowVal.bankSuggest
      }
    },
    saveSiteInfo () {
      let params = {}
      let obj = this.pageInfo
      for(let key in obj){
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }

      params.locateValue = JSON.stringify(params.locateValue)
      params.locateKey = JSON.stringify(params.locateKey)
      params.referItems = JSON.stringify(params.referItems)
      params.nearFinancePoint = params.nearFinancePoint.toString()
      params.generalInfo = JSON.stringify(params.generalInfo)
      console.log('params: ', params)
      saveStationInfo(params).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.$toast(res.data.businessMessage)
            this.$router.push({ name: 'villageScore', query: { score: JSON.stringify(res.data), stationName: this.pageInfo.stationName } })
          }
        }
      })
    },
    nextBtn () {
      this.checkParams()
    },
    getPageInfo () {
      getDictByPage('stationPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.sitePropList = []
            this.siteSourceList = []
            this.sitePropApiList = JSON.parse(data.data).placeNature
            this.siteSourceApiList = JSON.parse(data.data).recommendSource
            for (let item of this.sitePropApiList) {
              this.sitePropList.push(item.dictValue)
            }
            for (let item of this.siteSourceApiList) {
              this.siteSourceList.push(item.dictValue)
            }
            this.selectList = JSON.parse(data.data)
          }
        }
      })
    },
    clickSiteSource () {
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast('请先选择乡镇村')
      } else {
        this.siteSourceShow = true
      }
    },
    clickSiteProp () {
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast('请先选择乡镇村')
      } else {
        this.sitePropShow = true
      }
    },
    NumFormatter (val) {
      if (val.length == 1) {
        return val.replace(/[^1-9]/g, "")
      } else {
        return val.replace(/\D/g, "")
      }
    },
    countryClick () {
      if (!this.addressVal) {
        this.$toast('请先选择省市区县')
        return
      }
      this.countryShow = true
    },
    getAreaInfoCounty (countyNo) {
      this.countryList = []
      getAreaInfoCountyDict(countyNo).then((res) => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          this.countryListAll = data.townList
          for (let obj of this.countryListAll) {
            obj.children = obj.county
            for (let o of obj.children) {
              o.text = o.name
            }
            obj.text = obj.name
            this.countryList.push(obj)
          }
        }
      })
    },
    areaConfirm (val) {
      this.countryListAll = []
      this.pageInfoShowVal.countryVal = ''
      this.pageInfo.stationName = ''
      let code = ''
      this.addressVal = ''
      this.addressShow = false
      this.pageInfoShowVal.gps = ''
      for (let obj of val) {
        this.addressVal += obj.name + ' '
        code += obj.name
        this.isFirstShowLong = this.addressVal.length > 16
      }
      this.areaCode = val[2].code
      this.pageInfo.locateValue = {
        province: val[0].name,
        city: val[1].name,
        county: val[2].name
      }
      this.pageInfo.locateKey = {
        province: val[0].code,
        city: val[1].code,
        county: val[2].code
      }
      this.getAreaInfoCounty(this.areaCode)
    },
    countryConfirm (val, i) {
      this.countryShow = false
      this.pageInfoShowVal.countryVal = val.toString()
      this.isSecondShowLong = this.pageInfoShowVal.countryVal.length > 13
      this.pageInfo.stationName = val[1] + '服务站'
      if (this.pageInfo.stationName.indexOf("村委会") > 0) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("村委会", "")
      }
      if (this.pageInfo.stationName.indexOf("居委会") > 0) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("居委会", "");
      }

      if (this.pageInfo.stationName.indexOf("村") > 0 && this.pageInfo.stationName.length > 5) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("村", "")
      }


      let self = this
      self.pageInfo.lat = this.countryList[i[0]].county[i[1]].lat
      self.pageInfo.lng = this.countryList[i[0]].county[i[1]].lng
      console.log("经纬度：",self.pageInfo.lat,self.pageInfo.lng)
      if (self.pageInfo.lat == undefined || self.pageInfo.lng == undefined) {
        self.pageInfo.lat = 39.924074
        self.pageInfo.lng = 116.403419
      }

      self.center = [self.pageInfo.lng,self.pageInfo.lat]
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      geocoder.getAddress(self.center, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            console.log(result.regeocode.formattedAddress)
            self.pageInfoShowVal.gps = result.regeocode.formattedAddress

            self.$nextTick()
          }
        }
      })

      this.pageInfo.locateValue.town = this.countryList[i[0]].name
      this.pageInfo.locateValue.village = this.countryList[i[0]].county[i[1]].name
      this.pageInfo.locateKey.town = this.countryList[i[0]].areaCode
      this.pageInfo.locateKey.village = this.countryList[i[0]].county[i[1]].areaCode
      this.pageInfo.villageCode = this.countryList[i[0]].county[i[1]].areaCode

      let para = {
        villageCode: this.pageInfo.villageCode
      }
      getStationInfoByCode(para).then(res => {
        this.isSiteExist = false
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = data.data
            if (!json) {
              return
            }
            this.pageInfo.placeNature = json.placeNature ? json.placeNature : ''
            for (let item of this.sitePropApiList) {
              if (item.dictKey === this.pageInfo.placeNature) {
                this.pageInfoShowVal.placeNature = item.dictValue
              }
            }
            this.pageInfo.recommendSource = json.recommendSource ? json.recommendSource: ''
            for (let item of this.siteSourceApiList) {
              if (item.dictKey === this.pageInfo.recommendSource) {
                this.pageInfoShowVal.recommendSource = item.dictValue
              }
            }

          } else if (data.businessCode == 30017) {
            // 站点存在
            this.isSiteExist = true
          }
        }
      })
      getVillageInfoByAreaCode(this.pageInfo.villageCode).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            if (data.villageInfo) {
              self.pageInfoShowVal.otherInfo = data.villageInfo
            } else {
              self.pageInfoShowVal.otherInfo = ''
            }
            if (data.villageInfo) {
              self.pageInfo.households = data.households
            }
            if (data.villageInfo) {
              self.pageInfo.peopleNum = data.peopleNum
            }
          }
        }
      })
    },
    sitePropComfirm (val, i) {
      this.sitePropShow = false
      this.pageInfoShowVal.placeNature = val
      this.pageInfo.placeNature = this.sitePropApiList[i].dictKey
    },
    siteSourceComfirm (val, i) {
      this.siteSourceShow = false
      this.pageInfoShowVal.recommendSource = val
      this.pageInfo.recommendSource = this.siteSourceApiList[i].dictKey
    },
    checkParams () {
      if (this.isSiteExist) {
        this.$toast("站点已存在")
        return
      }
      if (!this.addressVal) {
        this.$toast("请选择省市区县")
        return
      }
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast("请选择省乡镇村")
        return
      }
      if (!this.pageInfoShowVal.gps) {
        this.$toast("请选择站点GPS位置")
        return
      }
      if (!this.pageInfoShowVal.recommendSource) {
        this.$toast("请选择站点推荐来源")
        return
      }
      if (!this.pageInfoShowVal.placeNature) {
        this.$toast("请选择站点场所性质")
        return
      }

      // pageTwo
      if (!this.pageInfo.households) {
        this.$toast('请输入村户数')
        return
      }
      if (this.pageInfo.households.length < 3) {
        this.$toast('村户数字段长度为3-5')
        return
      }
      if (!this.pageInfo.peopleNum) {
        this.$toast('请输入村人数')
        return
      }
      if (this.pageInfo.peopleNum.length < 3) {
        this.$toast('村人数字段长度为3-5')
        return
      }
      if (!this.pageInfo.aboRatio) {
        this.$toast('请选择原著居民占比')
        return
      }
      if (!this.pageInfo.perLand) {
        this.$toast('请输入人均耕地数')
        return
      }
      if (this.pageInfo.perLand > 99.9) {
        this.$toast('人均耕地数不能超过99.9亩')
        return
      }
      if (!this.pageInfo.houseDisposableFound) {
        this.$toast('请选择户均可支配资金')
        return
      }
      if (!this.pageInfo.carRatio) {
        this.$toast('请选择私家车比例')
        return
      }
      if (!this.pageInfo.livingDensity) {
        this.$toast('请选择居住密度')
        return
      }
      if (this.pageInfo.distinctiveCulture == '0') {
        this.pageInfo.regionalImpact = null
      }
      if (!this.pageInfo.distinctiveCulture) {
        this.$toast('请选择特色地方文化、历史名人等')
        return
      }
      if (!this.pageInfo.regionalImpact && this.pageInfo.distinctiveCulture == '1') {
        this.$toast('请选择地域影响')
        return
      }
      if (this.pageInfo.distinctiveCulture == '0') {
        this.pageInfo.regionalImpact = null
      }
      if (!this.pageInfo.demonstrationVillage) {
        this.$toast('请选择美好示范乡村、明星村落等')
        return
      }
      if (!this.pageInfo.demonstrationLevel && this.pageInfo.demonstrationVillage == '1') {
        this.$toast('请选择示范等级')
        return
      }
      if (this.pageInfo.demonstrationVillage == '0') {
        this.pageInfo.demonstrationLevel = null
      }

      if (!this.pageInfo.generalInfo.villageCollectiveEconomy) {
        this.$toast('请选择村集体经济')
        return
      }
      if (!this.pageInfo.generalInfo.recentDemolitionInfo) {
        this.$toast('请选择近年拆迁信息')
        return
      }
      if (!this.pageInfo.generalInfo.pedlarsMarket) {
        this.$toast('请选择集贸市场')
        return
      }
      if (!this.pageInfo.generalInfo.expressPoint) {
        this.$toast('请选择快递收发点')
        return
      }
      if (!this.pageInfo.generalInfo.paymentPoint) {
        this.$toast('请选择水电气等代缴点')
        return
      }
      if (!this.pageInfo.generalInfo.similarPoint) {
        this.$toast('请选择同类站点')
        return
      }

      if (this.pageInfo.nearFinancePoint.length == 0) {
        this.$toast('请选择周边金融网点')
        return
      }
      if (!this.pageInfo.nearFinancePointDistance) {
        this.$toast('请选择最近金融网点距离')
        return
      }

      // pageThree
      if (!this.pageInfo.mainIncomeSource) {
        this.$toast('请选择主要经济来源')
        return
      }
      if (!this.pageInfo.plantIndustry) {
        this.$toast('请选择种植业类型')
        return
      }
      if (!this.pageInfo.hasBreedIndustry) {
        this.$toast('请选择是否有养殖业')
        return
      }
      if (!this.pageInfo.breedIndustryType && this.pageInfo.hasBreedIndustry == '1') {
        this.$toast('选择养殖业类型')
        return
      }
      if (this.pageInfo.hasBreedIndustry == '0') {
        this.pageInfo.breedIndustryType = null
      }

      if (!this.pageInfo.hasManufacture) {
        this.$toast('请选择是否有制造业')
        return
      }
      if (!this.pageInfo.manufactureType && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择制造业类型')
        return
      }
      if (!this.pageInfo.manufactureForm && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择制造业主要形式')
        return
      }
      if (this.pageInfo.hasManufacture == '0') {
        this.pageInfo.manufactureType = null
        this.pageInfo.manufactureForm = null
        this.pageInfo.hasFamousCompany = null
      }
      if (!this.pageInfo.hasFamousCompany && this.pageInfo.hasManufacture == '1') {
        this.$toast('请选择有无知名企业')
        return
      }

      if (!this.pageInfo.hasServiceIndustry) {
        this.$toast('请选择是否有服务业')
        return
      }
      if (!this.pageInfo.serviceIndustryType && this.pageInfo.hasServiceIndustry == '1') {
        this.$toast('请选择服务业类型')
        return
      }
      if (!this.pageInfo.hasNearScenicSpots && this.pageInfo.hasServiceIndustry == '1') {
        this.$toast('请选择周边旅游景点')
        return
      }
      if (this.pageInfo.hasServiceIndustry == '0') {
        this.pageInfo.serviceIndustryType = null
        this.pageInfo.hasNearScenicSpots = null
      }
      if (!this.pageInfo.referItems.netSignal) {
        this.$toast('请选择网络信号')
        return
      }
      if (!this.pageInfo.referItems.townResident) {
        this.$toast('请选择是否为乡镇驻地村')
        return
      }
      if (!this.pageInfo.bankSuggest) {
        this.$toast('请选择行方建议')
        return
      }
      this.saveSiteInfo()
    },
    gpsClick () {
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast('请先选择乡镇村')
        return
      }
      this.mapshow = true
    },
    selectClick (obj, title) {
      this.popupShow = true
      this.popupList = []
      this.popuptitle = title
      for (let obj of this.selectList[obj]) {
        this.popupList.push(obj.dictValue)
      }
      this.popupListArr = this.selectList[obj]
      this.tempObj = obj
    },
    popupConfirm (value, index) {
      this.popupShow = false
      this.pageInfoShowVal[this.tempObj] = value
      this.pageInfo[this.tempObj] = this.popupListArr[index].dictKey

      this.pageInfo.manufactureType = this.pageInfoShowVal['manufactureType'];
      if (this.pageInfo.manufactureType && this.pageInfo.manufactureType.length > 15) {
        this.pageInfo.manufactureType = this.pageInfo.manufactureType.substring(0, 15) + '...';
      }
    },
    popupCancel (value) {
      this.pageInfoShowVal[value] = ''
      this.pageInfo[value] = ''
      if (value === 'manufactureType') {
        this.pageInfo.manufactureType = '';
      }
    },
    LandFormatter (val) {
      if (val > 99.9) {
        return 99.9
      } else {
        // 正则只能输入数字，一个小数点，第一位不能为小数点，保留1位小数，为正数
        return val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, '')
      }
    },
    handleRoll () {
      this.throttle(this.pageScroll)
    },
    pageScroll () {
      var height = this.$refs.content.offsetTop
      this.getOffsetTop(this.$refs.scrollEl.scrollTop)
      this.$refs.scrollEl.scrollTop > height ? (this.scrolled = true) : (this.scrolled = false)
    },
    getOffsetTop (scroll) {
      var el = this.$el.querySelector('.tab-content')
      let count = 0
      var content = []

      while (count < el.children.length) {
        content[count] =
          el.children[count].offsetTop + el.children[count].clientHeight - 118
        count++
      }
      content.every((item, index) => {
        if (item > scroll) {
          this.selectedTab = index
          this.setLine()
          return false
        }
        return true
      })
      content.every((item, index) => {
        var h = document.documentElement.clientHeight || document.body.clientHeight;
        if (item == scroll + h - 151 && index === 2) {
          this.selectedTab = index
          this.setLine()
          return false
        }
        return true
      })
    },
    throttle (foo) {
      if (foo.timer) return
      foo.timer = setTimeout(() => {
        foo()
        foo.timer = null
      }, 100)
    },
    setLine () {
      var that = this
      this.$nextTick(() => {
        if (!that.$refs.tabs) return
        var tab = that.$refs.tabs[this.selectedTab]
        that.lineStyle = {
          width: tab.offsetWidth + 'px',
          transform: 'translateX(' + tab.offsetLeft + 'px)'
        }
      })
    },
    changeTab (index) {
      this.selectedTab = index
      var el = this.$el.querySelector('.tab-content')
      if (el.children[index]) {
        this.$el.querySelector('.newsite-flex').scrollTo(0, el.children[index].offsetTop - 118)
        // if (index === 1) {
        //   this.$el.querySelector(".newsite-flex").scrollTo(0, el.children[2].offsetTop - 50);
        // }
      }
      var height = this.$el.offsetTop
      this.$refs.scrollEl.scrollTop > height ? (this.scrolled = true) : (this.scrolled = false)
      this.$refs.scrollEl.removeEventListener('scroll', this.handleRoll)
      setTimeout(() => {
        this.$refs.scrollEl.addEventListener('scroll', this.handleRoll)
      }, 200)
      this.setLine()
    },
  },
  beforeRouteLeave (to, from, next) {
    // this.$store.dispatch('mapshow/mapClick', false)
    if (to.name == 'villageScore') {
    } else {
      this.$store.dispatch('keepAliveState/removeKeepAlive', 'NewSite')
    }
    next()
  },
}

</script>

<style lang="less" scoped>
.newSiteWarpNS {
  background: #f5f5f5;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  .van-field {
    font-size: 17px;
    color: red;
  }
  .flex {
    display: flex;
  }
  .img-icon{
    width:0.4rem;
  }
  .newsite-flex {
    padding-bottom: 0.2rem;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    color: #3f7c53;
  }
  .mt2 {
    margin-top: 0.2rem;
  }
  .title {
    text-align: left;
    background: #ffffff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.3rem;
    color: #333333;
    font-weight: bold;
    font-size: 15px;
    margin-top: 0.2rem;
  }
  div.title:before {
    width: 8px;
    height: 5px;
    color: #3f7c53;
    content: "| ";
  }
  .tab-content {
    padding-bottom: 1rem;
  }
  .radiogroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .checkboxitem {
    background: #fff;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0.2rem 0.32rem;
  }
  .nextBtn {
    width: 100%;
    padding: 0.24rem;
    background: #f7f8f9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .btn-active {
    height: 0.98rem;
    line-height: 0.98rem;
    text-align: center;
    background: #3f7c53;
    border-radius: 0.08rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    opacity: 1;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .van-button {
      width: 100%;
    }
  }
  .newSiteWarpNS-top {
    position: fixed;
    height: 118px;
    z-index: 100;
    width: 100%;
    background-color: white;
    .newSiteWarpNS-step {
    }
  }
  .detail-content {
    padding-top: 118px;
    padding-bottom: 100px;
  }
}
.mapDiv{
  background:rgba(0,0,0,0.3);
  padding: 10px;
  width: 375px;
  height: 667px;
}
.close {
  text-align: right;
  margin-bottom: 14px;
  .van-icon {
    background: url("../../assets/img/close.png");
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
  }

}
</style>
<style>
.newSiteWarpNS input {
  text-align: right;
}
.newSiteWarpNS .van-picker__confirm {
  color: #3f7c53;
}
.newSiteWarpNS .van-picker__cancel {
  color: #3f7c53;
}
.newSiteWarpNS .van-tab__text {
  font-size: 15px;
}
.newSiteWarpNS .van-cell {
  min-height: 48px;
}
.newSiteWarpNS .van-cell__title span {
  font-size: 15px;
  color: #333333;
  white-space: nowrap;
  display: inline-block;
}
.newSiteWarpNS .van-field__control {
  font-size: 15px;
}
.newSiteWarpNS .van-radio {
  font-size: 15px;
}
  #amapDemo{
    width:100%;
    height: calc(100% - 60px);
  }

</style>
